import { Component, h, Host, Prop } from '@stencil/core';
import { DateTime } from 'luxon';

@Component({
    tag: 'event-widget',
    styleUrl: 'event-widget.scss',
    shadow: false,
})
export class EventWidget {
    #ACTIVITIES_CALENDAR: string = 'f0cbb69989f3249b88274eca00c1897e19363672f9a5c16d79b8483afca4e88e@group.calendar.google.com';
    #API_KEY: string = 'AIzaSyBEayAx8XLif1wb94LYTRzuHEeSSNq5ChI';

    @Prop()
    filter: string;

    event: any;

    componentWillLoad() {
        const timeMin = DateTime.now().startOf('day').toUTC();
        return fetch(`https://www.googleapis.com/calendar/v3/calendars/${this.#ACTIVITIES_CALENDAR}/events?q=${this.filter}&key=${this.#API_KEY}&timeMin=${timeMin}&maxResults=1&singleEvents=true&orderBy=startTime`)
            .then(response => response.json())
            .then(calendar => {
                this.event = calendar.items[0];
            });
    }

    render() {
        return this.event && this.#renderEvent();
    }

    #renderEvent() {
        return (<Host>
            <h3 class="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">Næste {this?.filter}</h3>
            <div class="el-content uk-panel uk-margin-top">
                <p class="ew-date-time">{DateTime.fromISO(this.event?.start.dateTime).setLocale('da-DK').toFormat('DDDD kl. T')}</p>
                {this.event?.description > '' && <p>{this.event?.description}</p>}
            </div>
        </Host>);
    }
}
